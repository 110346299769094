const DEFAULT_API_URL = '/api'
const DEFAULT_LEADS_API_URL = '/gtr-api'

export const API_USER = Object.freeze({
  BASE_API_URL: `${DEFAULT_API_URL}/user/`,
  LOGOUT: 'logout.php',
  FORGET_PASSWORD: 'forget_password.php',
  AUTH: 'auth.php',
  UPDATE_SETTINGS: 'update_settings.php',
  UPDATE_PASSWORD: 'update_password.php',
  RESET_PASSWORD: 'password.php',
})

export const API_AUTH = Object.freeze({
  SESSION_REFRESH: '/auth/session/refresh',
  INVITE_USER: '/auth/user/password/reset/token',
  CHANGE_PASSWORD: '/auth/change-password',
})

export const API_PROFILE_HUB = {
  BASE_API_URL: `${DEFAULT_API_URL}/ph/`,
  BASE_API_PROXY_URL: `${DEFAULT_API_URL}/node/segment/`,
  DASHBOARD: 'dashboard.php',
  IMPORT: 'import_checkfile.php',
  CRITERIA_GTR: 'criteriasGtr.php',
  CRITERIA_CUSTOM: 'criteriasCustom.php',
  CREATE_TEMPLATE: 'import_create_empty_file.php',
  PROFILE: 'profile_id.php',
  PROFILES: `searchProfiles`,
  SEGMENTS: 'segments.php',
  OPTIN: 'update_optin.php',
  EXPORT_PROFILES: 'export_profiles.php',
  FILE: 'get_file.php',
  CRITERIA_BRAND: 'custom_criterias.php',
  CAMPAIGN_MAPPING: 'campaign_mapping.php',
  MAPPING_TRANSFER: 'mapping_transfer.php',
  MAPPING_TRANSFER_QUESTION: 'mapping_transfer_question.php',
  MAPPING_TRANSFER_QUESTION_ANSWER: 'mapping_transfer_question_answer.php',
  MAPPING_CRITERIA: 'mapping_criterias.php',
  AVAILABLE_V1_QA: 'available_v1_QA.php',
}

export const API_AUTO_REPLY = {
  BASE_API_URL: `${DEFAULT_API_URL}/ar/`,
  NAV_REVIEW_COUNT: 'nav_review_counts.php',
  REVIEWS: 'review_autoreply.php',
  BRAND_UUID: 'brand_id.php',
  REVIEW_ID: 'review_id.php',
  SEND_RESPONSE: 'send_response.php',
  SEND_ALL_RESPONSES: 'send_all_responses.php',
  BOT_EVALUATION: 'bot_evaluation.php',
  SETTINGS: 'settings.php',
  REVIEWS_LIST: 'reviews.php',
  AVAILABLE_FILTERS: 'available_filters.php',
  REPORT_RESPONSE: 'report_response.php',
  DEACTIVATE_REVIEW: 'deactivate_review_id.php',
}

export const API_TOUCHPOINTS = {
  BASE_API_URL: `${DEFAULT_API_URL}/sa/qr/`,
  BASE_API_PROXY_URL: `${DEFAULT_API_URL}/node/touchpoint/`,
  BASE_API_PROXY_PRODUCT_URL: `${DEFAULT_API_URL}/node/product/`,
  BRAND_SETTINGS: `brand_get_settings.php`,
  PRODUCT: `fetchExternalProduct`,
  PRODUCT_TOUCHPOINT: `createProductTouchpoint`,
  CUSTOM_TOUCHPOINT: `createCustomTouchpoint`,
  SEARCH_TOUCHPOINTS: `searchTouchpoints`,
  SEARCH_FILTERS: `getSearchFilters`,
  FIND_TOUCHPOINT: `findTouchpoint`,
  UPDATE_TOUCHPOINT: `updateTouchpoint`,
  DOWNLOAD_ZIP: `getZipTouchpointQrUrl`,
  EXPORT_TOUCHPOINT: `exportTouchpoint`,
  IMPORT_TOUCHPOINT: `importTouchpoint`,
  REFRESH_LANDINGPAGE: `refreshLandingPage`,
  REFRESH_QRCODES: `refreshQrCode`,
}

export const GTR_CORE = {
  BASE_API_URL: `/gtr-core/`,
  AUTH: 'auth',
  CLIENTS: 'clients',
  ROLES: 'roles',
  USERS: 'users',
  USER: 'user',
  MENUS: 'menus',
  MY_SELF: '/myself',
}

export const API_SA = {
  BASE_API_URL: `${DEFAULT_API_URL}/sa/`,
  USER_ASSOCIATION: 'users-association.php',
}

export const NODE_API_DOMAIN_LIST = {
  CATALOG: 'catalog',
  LANDING: 'landing',
  LEAD: 'lead-generation',
  LOCATOR: 'locator',
  OPPORTUNITY: 'opportunity',
  NOTIFICATIONS: 'notifications',
}

export const API_LOCATION = {
  BASE_API_URL: `${DEFAULT_LEADS_API_URL}/${NODE_API_DOMAIN_LIST.LOCATOR}`,
}

export const API_LEADS = {
  BASE_API_URL: `${DEFAULT_LEADS_API_URL}/${NODE_API_DOMAIN_LIST.LEAD}`,
  CLIENT: 'clients',
  FORMS: 'forms',
  CONVERSION: 'conversion',
}

export const API_OPPORTUNITY = {
  BASE_API_URL: `${DEFAULT_LEADS_API_URL}/${NODE_API_DOMAIN_LIST.OPPORTUNITY}/`,
  CLIENTS: 'clients',
}
